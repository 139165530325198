.rkt-menu {
  padding: 0.75rem 0 0.3125rem;
  border-radius: 3px;
  min-width: 11.3125rem;
  position: absolute;
  border: solid 1px #e7e8ea;
  background-color: #ffffff;
  box-shadow: 0 3px 6px #00000029;
}

.rkt-menu .rkt-menu-list {
  margin: 0;
  padding: 0;
}

.rkt-menu .rkt-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.rkt-menu .rkt-menu-item-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  color: #bdbdbd;
  border-radius: 0;
  user-select: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.357;
  font-family: 'Nunito Sans', sans-serif;
}

.rkt-menu .rkt-menu-item-link:focus {
  outline: 0 none;
  outline-offset: 0;
}

.rkt-menu .rkt-menu-item-link:hover {
  color: #01bcaa;
}

.rkt-menu-item--separator {
  border-bottom: 1px solid #bdbdbd33;
}

.rkt-menu-item--static-text {
  font-size: 0.875rem;
  line-height: 1.357;
  height: 2.125rem;
  padding: 0.1875rem 1rem 0.1875rem 1.3125rem;
  display: flex;
  align-items: center;

  .rkt-menu-item-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    overflow: hidden;
  }
}

.rkt-menu-item-link {
  height: 2.125rem;
  padding: 0.1875rem 1rem 0.1875rem 1.3125rem;

  .rkt-menu-item-text {
    font-size: 0.875rem;
    line-height: 1.357;
  }
}

.rkt-menu-item-icon {
  font-size: 0.875rem;
  width: 0.875rem;
  margin-right: 1rem;
}
